import { sendAnalyticsEvent } from '@faststore/sdk'
import { useCallback } from 'react'
import type { AddToCartEvent, CurrencyCode } from '@faststore/sdk'
import type { AnalyticsItem } from 'src/sdk/analytics/types'
import { useBraindwSendGA } from 'src/components/hooks/useBraindwSendGA'

import { useSession } from '../session'
import { cartStore } from './index'

declare global {
  interface Window {
    Insider: Array<{ type: string } & Record<string, any>>
  }
}

export const useBuyButton = (
  item: any,
  itemValues?: any,
  eventAction?: string
) => {
  const {
    currency: { code },
  } = useSession()

  const { sendEventGA } = useBraindwSendGA()

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      if (!item) {
        return
      }

      if (eventAction) {
        sendEventGA(itemValues, eventAction, 'addToCartBraindw')
      }

      sendAnalyticsEvent<AddToCartEvent<AnalyticsItem>>({
        name: 'add_to_cart',
        params: {
          currency: code as CurrencyCode,
          value: item.price * item.quantity,
          items: [
            {
              item_id: item.itemOffered.isVariantOf.productGroupID,
              item_name: item.itemOffered.isVariantOf.name,
              item_brand: item.itemOffered.brand.name,
              item_variant: item.itemOffered.sku,
              quantity: item.quantity,
              price: item.price,
              discount: item.listPrice - item.price,
              currency: code as CurrencyCode,
              item_variant_name: item.itemOffered.name,
              product_reference_id: item.itemOffered.gtin,
            },
          ],
        },
      })

      window.InsiderQueue = window.InsiderQueue || []
      window.InsiderQueue.push({
        type: 'add_to_cart',
        value: {
          id: item.id,
          name: item.itemOffered.isVariantOf.name,
          unit_price: item.listPrice,
          unit_sale_price: item.price,
          quantity: item.quantity,
          url: window.location.href,
          product_image_url: item.itemOffered.image[0].url,
          custom: {
            merchandiser: item.seller,
          },
        },
      })
      cartStore.addItem(item)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [code, item]
  )

  return {
    onClick,
    'data-testid': 'buy-button',
    'data-sku': item?.itemOffered.sku,
    'data-seller': item?.seller.identifier,
  }
}
