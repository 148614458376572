import { ProductDetailsFragment_ProductFragment } from '@generated/graphql'
import { useEffect, useState } from 'react'
import getQuotation from 'src/api/getMulticurrencyQuotation'
import { useProduct } from 'src/sdk/product/useProduct'
import isProductMKT from 'src/utils/productMKTChecker'

declare global {
  interface Window {
    InsiderQueue: Array<{ type: string } & Record<string, any>>
  }
}

interface Props {
  product: ProductDetailsFragment_ProductFragment
}

const InsiderEventProduct = ({ product: staleProduct }: Props) => {
  const { data } = useProduct(staleProduct.id, {
    product: staleProduct,
  })
  const [quotationValue, setQuotationValue] = useState<number>(1)

  useEffect(() => {
    const fetchQuotation = async () => {
      try {
        const storedQuotation = sessionStorage.getItem('productMKTQuotation')
        if (storedQuotation) {
          setQuotationValue(Number(storedQuotation))
          return
        }

        const fetchedQuotation = await getQuotation()
        sessionStorage.setItem('productMKTQuotation', fetchedQuotation)
        setQuotationValue(Number(fetchedQuotation))
      } catch (error) {
        console.error('Error fetching quotation:', error)
        setQuotationValue(1)
      }
    }

    fetchQuotation()
  }, [])

  useEffect(() => {
    if (data) {
      const isMKT = isProductMKT(
        data.product.offers.offers[0].seller?.identifier
      )

      window.InsiderQueue = window.InsiderQueue || []
      window.InsiderQueue.push({
        type: 'product',
        value: {
          id: data.product.id,
          name: data.product.name,
          taxonomy: data.product.breadcrumbList.itemListElement.map(
            (t) => t.name
          ),
          unit_price: isMKT
            ? data.product.offers.offers[0].price / quotationValue
            : data.product.offers.offers[0].price,
          unit_sale_price: isMKT
            ? data.product.offers.offers[0].listPrice / quotationValue
            : data.product.offers.offers[0].listPrice,
          url: window.location.href,
          stock:
            data.product.offers.offers[0].availability ===
            'https://schema.org/InStock'
              ? 1
              : 0,
          product_image_url: data.product.image[0].url,
        },
      })

      window.InsiderQueue.push({
        type: 'currency',
        value: isProductMKT(data.product.offers.offers[0].seller?.identifier)
          ? 'USD'
          : 'UYU',
      })

      window.InsiderQueue.push({ type: 'init' })
    }
  }, [data])

  return null
}

export default InsiderEventProduct
