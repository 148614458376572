import axios from 'axios'

// TODO: After backend deployment of this task whe can remove the actual url domain and change for the store ID, so it will be possible to access normally the quotation info.
// import storeConfig from '../../store.config'

export default async function getQuotation() {
  // TODO: After backend deployment of this task whe can remove the actual url domain and change for the store ID, so it will be possible to access normally the quotation info.
  // const {
  //   api: { storeId },
  // } = storeConfig

  const URL = 'https://ec1201804--tatauyshopqa.myvtex.com/_v/pvt/quotation'

  try {
    const { data } = await axios.get(URL, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    return JSON.stringify(data)
  } catch (err) {
    throw new Error(`An error occurred: ${err.message}`)
  }
}
